import { ApolloClient } from '@apollo/client';
import { GetServerSideProps } from 'next';
import { ReactElement } from 'react';

import Page, { PageOptionsProps } from 'components/Page/Page';
import ErrorPage from 'containers/ErrorPage';
import HomeLanding from 'containers/home/HomeLanding/HomeLanding';

import {
  addApolloStateToProps,
  setInitialApolloCacheStateSSR,
} from 'lib/apollo/utils';
import { HomePageEntry, loadHomePage } from 'lib/contentful';
import { HomepageLinkedData } from 'lib/metatags/homepage';
import { VerishopOrganizationLinkedData } from 'lib/metatags/organization';
import { pageRoutes } from 'lib/routes';
import { MetaTags } from 'lib/routes/metaTags';
import { displayUrlToCanonicalUrl } from 'lib/routes/utils';
import Logger from 'lib/utils/Logger';
import {
  CONTENT_PAGE_TRACKING_CLASS,
  gtmAttributesForHomePage,
} from 'lib/utils/tracking';

import { GET_DYNAMIC_MODULES } from 'components/DynamicModules/data/graphql/DynamicModule.gql';

import { SiteFurniture } from 'types/app';
import { DynamicModuleResponse } from 'types/generated/api';

import { initApollo } from '../lib/apollo';

type HomePageProps = {
  dynamicModulesResponse: DynamicModuleResponse[];
  errorMessage?: string;
  homePageEntry?: HomePageEntry;
  initialState?: unknown;
  loading: boolean;
  metaDescription?: string;
  metaTitle?: string;
  pageConfigOptions?: PageOptionsProps;
  siteFurniture: SiteFurniture;
};

const HomePage = (props: HomePageProps): ReactElement => {
  const {
    dynamicModulesResponse,
    errorMessage,
    homePageEntry,
    metaDescription,
    metaTitle = 'Verishop Home Page',
    pageConfigOptions,
    siteFurniture,
  } = props;

  if (errorMessage) {
    return <ErrorPage siteFurniture={siteFurniture} />;
  }

  const metaTags: MetaTags = {
    canonicalUrl: displayUrlToCanonicalUrl(pageRoutes.home.displayUrl()),
    description: metaDescription,
    title: metaTitle,
  };

  return (
    <Page
      className={CONTENT_PAGE_TRACKING_CLASS}
      dataAttributes={gtmAttributesForHomePage()}
      metaTags={metaTags}
      options={pageConfigOptions}
      siteFurniture={siteFurniture}
    >
      <HomepageLinkedData />
      <VerishopOrganizationLinkedData />
      {homePageEntry && (
        <HomeLanding
          dynamicModulesResponse={dynamicModulesResponse}
          homePageEntry={homePageEntry}
        />
      )}
    </Page>
  );
};

const getDynamicModules = async (apolloClient: ApolloClient<unknown>) => {
  try {
    const response = await apolloClient.query({
      query: GET_DYNAMIC_MODULES,
      variables: {
        modules: [
          {
            module: 'GENERAL',
            segment: 'BEST_SELLERS',
          },
        ],
      },
    });

    const dynamicModulesResponseData = response?.data?.getDynamicModules ?? [];
    if (dynamicModulesResponseData.length === 0) {
      Logger.error('Homepage DynamicModules API returned empty modules');
    }

    return dynamicModulesResponseData;
  } catch (error) {
    Logger.error(
      'Homepage DynamicModules API failed with error: ',
      JSON.stringify(error, null, 2)
    );

    return [];
  }
};

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const apolloClient = initApollo.verishopServer();
  const pageConfigOptions: PageOptionsProps | undefined = getPageConfigOptions(
    req.headers.host
  );

  const [homePageEntry, dynamicModulesData] = await Promise.all([
    loadHomePage(pageConfigOptions?.homePageId),
    pageConfigOptions?.showDynamicModules && getDynamicModules(apolloClient),
    setInitialApolloCacheStateSSR(apolloClient),
  ]);

  const metaDescription = homePageEntry?.fields.metaDescription;
  const metaTitle = homePageEntry?.fields.metaTitle;

  return {
    props: await addApolloStateToProps(apolloClient, {
      dynamicModulesResponse: dynamicModulesData,
      homePageEntry,
      loading: false,
      metaDescription,
      metaTitle,
      pageConfigOptions,
    }),
  };
};

export function getPageConfigOptions(
  host?: string
): PageOptionsProps | undefined {
  if (!host) {
    return;
  }
  let pageConfigOptions: PageOptionsProps = {
    homePageId: '',
    showLiveShopBanner: false,
    showMarketingBanner: false,
    showPageFooter: true,
    showPageHeader: true,
    showSiteWideBanner: true,
  };

  // TODO: This will be store in DB
  switch (host) {
    case 'whitelabel.verishop.com':
    case 'goodful.verishop.com': {
      pageConfigOptions = {
        ...pageConfigOptions,
        homePageId: '1EC6yuzqstSVKXdXY3ypZE',
      };
      break;
    }
    case 'www.verishop.com':
    case 'beta.verishop.com':
    default: {
      pageConfigOptions = {
        ...pageConfigOptions,
        homePageId: '5RsCDjng0CkEV7fJbbh6w7',
      };
    }
  }

  return pageConfigOptions;
}

export default HomePage;

import { DynamicModuleData } from './DynamicModulesManager';

import Logger from 'lib/utils/Logger';

import { DynamicModuleResponse } from '../../types/generated/api';
import { Product } from '../ProductGrid/ProductCard/ProductCardApiQuery.gql';
import { ProductCardData } from '../ProductGrid/ProductCard/types';
import { convertApiProductToProductCardData } from '../ProductGrid/ProductCard/utils/convertApiProductToProductCard';

export const convertDynamicModulesResponseToDynamicModulesData = (
  dynamicModulesResponse: DynamicModuleResponse[]
): DynamicModuleData[] => {
  if (!dynamicModulesResponse || dynamicModulesResponse.length === 0) {
    return [];
  } else {
    return dynamicModulesResponse.map(module => {
      const { identifier, products, ...rest } = module;

      const productsCardData: ProductCardData[] = [];

      if (products) {
        products.forEach((product, index) => {
          try {
            const productCardData = convertApiProductToProductCardData(
              (product as unknown) as Product,
              index
            );
            productsCardData.push(productCardData);
          } catch (error) {
            Logger.error(
              'Dynamic Module: Failed to convert API product to ProductCardData: ',
              error
            );
          }
        });
      }

      return {
        ...rest,
        identifier: identifier || '',
        ...(products && {
          products: productsCardData,
        }),
      };
    });
  }
};
